<template>
  <div class="env-container">
    <!-- 关联环境监测设备 -->
    <div>
      <div class="filter-panel">
        <CSSelect>
          <select v-model="conditions.state" ref="selec">
            <option v-for="item in equipmentList" :key="item.id" :value="item">
              {{ item.name }}
            </option>
          </select>
        </CSSelect>
        <div
          style="
            vertical-align: middle;
            margin-bottom: 15px;
            display: inline-block;
          "
        >
          <input
            type="text"
            placeholder="搜索设备编号/设备型号/厂家品牌/设备所在位置"
            v-model="conditions.queryInput"
            style="
              color: #000;
              width: 320px;
              height: 30px;
              padding-left: 10px;
              border-radius: 4px;
              border: 1px solid #979797;
              margin-right: 20px;
            "
          />
        </div>
        <button type="button" class="btn btn-primary btn-sm" @click="query()">
          查询
        </button>
      </div>
      <!-- ---------------------------------------------------------------------------- -->
      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button class="btn btn-primary sticky-right" @click="addEquipmentBtn">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                环境监测设备
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>设备类型</th>
              <th>设备编号</th>
              <th>设备型号</th>
              <th>厂家品牌</th>
              <th>设备所在位置</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in allEquipment" :key="item.eid">
              <td class="timeH">{{ item.createTime }}</td>
              <td>
                {{ item.type == 1 ? "室外气象站" : "室内环境监测传感器" }}
              </td>
              <td>{{ item.no }}</td>
              <td>{{ item.model }}</td>
              <td>{{ item.brandName }}</td>
              <td>{{ (item.buildingName?item.buildingName:"")+(item.floor?item.floor+'层':"")+item.specificLocation }}</td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu" style="width: 76px">
                    <li @click="modifyBtn(item)">
                      <a>修改</a>
                    </li>
                    <li @click="deleteBtn(item)">
                      <a>删除</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination
          name="pagination"
          componentName="Pagination"
        ></Pagination>
      </div>
      <UpdateDevice @updateDevice="query()"></UpdateDevice>
    </div>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import UpdateDevice from "@/components/UpdateDevice";
import { selectQueryEquipmentUrl, envDeleteEquipmentUrl } from "@/requestUrl";
export default {
  data() {
    return {
      filterHeight: 0,
      conditions: {
        state: {
          id: "",
          name: "设备类型不限",
        },
        queryInput: "",
      },
      //设备类型
      equipmentList: [
        {
          id: "",
          name: "设备类型不限",
        },
        {
          id: 1,
          name: "室外气象站",
        },
        {
          id: 2,
          name: "室内环境监测传感器",
        },
      ],
      //设备列表
      allEquipment: [],
    };
  },
  components: {
    CSSelect,
    Pagination,
    UpdateDevice,
    CSTable,
  },
  methods: {
    //添加环境监测设备按钮
    addEquipmentBtn() {
      this.$vc.emit("equipment", "edit", { isShow: true, uppDate: false });
    },
    //删除
    deleteBtn(item) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定要删除吗?`,
        onConfirm: () => {
          this.$fly
            .get(envDeleteEquipmentUrl, {
              id: item.eid,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.$vc.toast("删除成功");
              this.$CSDialog.instance.closeDialog();
              this.query();
            });
        },
      });
    },
    //修改
    modifyBtn(item) {
      this.$vc.emit("equipment", "edit", { isShow: true, uppDate: item });
    },
    query(page = 1, Size = 10) {
      this.$fly
        .post(selectQueryEquipmentUrl, {
          regionCode: this.$vc.getCurrentRegion().code, //区域编码 //区域code
          type: this.conditions.state.id, //类型 设备类型  1:室外气象站  2:室内环境监测传感器
          search: this.conditions.queryInput, //搜索框内容
          pageSize: Size, //页面大小
          pageNo: page, //页码
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          if (page == 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total || res.data.datas.length,
              pageSize: Size,
              currentPage: page,
            });
          }
          this.allEquipment = res.data.datas;
        });
    },
    queryDown(e) {
      if (e.keyCode == 13) {
        this.$refs.selec.blur();
        this.query();
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.queryDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryDown);
  },
  deactivated(){
     window.removeEventListener("keydown", this.queryDown);
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", (res) => {
      this.query(res);
    });
    this.query();
  },
};
</script>

<style scoped>
.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}
.item {
  text-align: center;
}
th {
  text-align: center;
}
td {

}

.timeH{
  min-width: 140px !important;
}

</style>
