<template>
  <div class="out-container">
    <div>
      <div class="filter-panel">
        <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
          <el-date-picker
            type="datetime"
            placeholder="请选择开始时间"
            v-model="conditions.startTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"> </span>
        <CSSelect i-width="36rpx" style="width: 230px">
          <el-date-picker
            type="datetime"
            placeholder="请选择结束时间"
            v-model="conditions.endTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <div
          style="
            vertical-align: middle;
            margin-bottom: 15px;
            display: inline-block;
          "
        >
          <input
            type="text"
            placeholder="搜索设备编号/设备型号/厂家品牌/设备所在位置"
            v-model="conditions.queryInput"
            style="
              color: #000;
              width: 320px;
              height: 30px;
              padding-left: 10px;
              border-radius: 4px;
              border: 1px solid #979797;
              margin-right: 20px;
            "
          />
        </div>
        <button type="button" class="btn btn-primary btn-sm" @click="query()">
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="filterHeight">
          <template v-slot:thead>
            <tr>
              <th>获取数据时间</th>
              <th>设备编号</th>
              <th>设备型号</th>
              <th>厂家品牌</th>
              <th>设备所在位置</th>
              <th>风向</th>
              <th>风速</th>
              <th>湿度</th>
              <th>温度</th>
              <th>大气压</th>
              <th>降水量</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in queryList" :key="item.id">
              <td class="timeTd">
                <span>{{ item.createTime }}</span>
              </td>
              <td>
                <span>{{ item.no }}</span>
              </td>
              <td>
                <span>{{ item.model }}</span>
              </td>
              <td>
                <span>{{ item.brandName }}</span>
              </td>
              <td>
                <span>{{ item.specificLocation }}</span>
              </td>
              <td>
                <span>{{ item.windDirection }}°</span>
              </td>
              <td>
                <span>{{ item.windSpeed }}m/s</span>
              </td>
              <td>
                <span>{{ item.humidity }}%RH</span>
              </td>
              <td>
                <span>{{ item.temperature }}°C</span>
              </td>
              <td>
                <span>{{ item.barometricPressure }}kPa</span>
              </td>
              <td>
                <span>{{ item.precipitation }}mm</span>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination
          :key="'out'"
          name="pagination"
          componentName="Pagination"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import { outdoorWeatherUrl } from "@/requestUrl";
import CSTable from "@/components/common/CSTable";
import dayjs from "dayjs";

export default {
  data() {
    return {
      filterHeight: 0,
      conditions: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        queryInput: "",
      },
      queryList: [],
    };
  },
  components: {
    CSTable,
    CSSelect,
    Pagination,
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
    this.$vc.on(this.$route.path, "pagination", "page_event", this.qu);
    this.query();
  },
  methods: {
    qu(res) {
      this.query(res);
    },
    //查询
    query(page = 1, Size = 10) {
      this.$fly
        .post(outdoorWeatherUrl, {
          regionCode: this.$vc.getCurrentRegion().code, //区域编码 //区域code
          startDate: this.conditions.startTime, //起始时间
          endDate: this.conditions.endTime, //结束时间
          pageSize: Size,
          pageNo: page,
          search: this.conditions.queryInput,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          if (page == 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total || res.data.datas.length,
              pageSize: Size,
              currentPage: page,
            });
          }
          this.queryList = res.data.datas;
        });
    },
    queryDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.queryDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.queryDown);
  },
  deactivated(){
      window.removeEventListener("keydown", this.queryDown);
  }
};
</script>

<style scoped>
td {
  min-width: 100px !important;
  box-sizing: border-box;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.timeTd {
  min-width: 140px !important;
}
</style>
