<template>
  <div class="mon-container">
    <!-- 监测数据记录 -->
    <CSTabBar
      :tabbar="tabBar"
      :checked-tab="checkedTaBar"
      @changeTabBar="changeTabBar"
    />
    <template v-if="checkedTaBar == 1" >
      <OutdoorWeatherStation ></OutdoorWeatherStation>
    </template>
    <template v-if="checkedTaBar == 2"> 
        <EnvironmentalMonitor></EnvironmentalMonitor>
    </template>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import EnvironmentalMonitor from "@/views/EnvironmentalMonitoring/MonitoringRecord/EnvironmentalMonitor";
import OutdoorWeatherStation from "@/views/EnvironmentalMonitoring/MonitoringRecord/OutdoorWeatherStation";
export default {
  data() {
    return {
      tabBar: {
        1: "室外气象站",
        2: "室内环境监测传感器",
      },
      checkedTaBar: 1,
    };
  },
  methods: {
    changeTabBar(index) {
      if (this.checkedTaBar == +index) {
        return;
      }
      this.checkedTaBar = +index;
    },
  },
  components: {
    CSTabBar,
    EnvironmentalMonitor,
    OutdoorWeatherStation,
  },
};
</script>

<style>
</style>