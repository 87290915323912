<template>
  <!-- 环境监测 -->
  <div class="env-container">
    <CSTabBar
      :tabbar="tabBar"
      :checked-tab="checkedTaBar"
      @changeTabBar="changeTabBar"
    />

     <template v-if="checkedTaBar==1">
       <MonitoringDataRecord></MonitoringDataRecord>
     </template>
     <template v-if="checkedTaBar==2">
       <EnvironmentalMonitoringEquipment></EnvironmentalMonitoringEquipment>
     </template>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import Pagination from "@/components/Pagination.vue";
import MonitoringDataRecord from '@/views/EnvironmentalMonitoring/MonitoringDataRecord.vue';
import EnvironmentalMonitoringEquipment from '@/views/EnvironmentalMonitoring/EnvironmentalMonitoringEquipment';
export default {
  data() {
    return {
      tabBar: {
        1: "监测数据记录",
        2: "关联环境监测设备",
      },
      checkedTaBar: 1,
    };
  },
  components: {
    CSTabBar,
    Pagination,
    MonitoringDataRecord,
    EnvironmentalMonitoringEquipment
  },
  methods: {
    changeTabBar(index) {
      if (this.checkedTaBar == +index) {
        return;
      }
      this.checkedTaBar = +index;
    },
  },
};
</script>

<style scoped>
th{
  text-align: center;
}
td{
  text-align: center;
}
.env-container{
  font-size: 13px;
}
</style>